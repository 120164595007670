import HomePage from "./components/Home";
import CatchEmAllPage from "./components/CatchEmAll";

export const ROUTES = [
	{
		path: "/",
		element: <HomePage />,
		// errorElement: 
	},
	{
		path: "/league-champ-mastery",
		element: <CatchEmAllPage />,
	},
];