import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {
	return (
		<div className="Header">
			<div>Header</div>
			{/* <Link  to="/league-champ-mastery">
				League Champ Mastery
			</Link >
			<Link  to="/league-champ-mastery">
				League Champ Mastery
			</Link > */}
		</div>
	);
}
