import { Link } from "react-router-dom";
import "./Home.css";
import ProjectCard from "./ProjectCard";

function HomePage() {
	return (
		<div className="HomePage">
			<div className="card-container">
				<ProjectCard name='Custom Dashboards — League Of Legends' link='/league-champ-mastery' imagePath='/league.jpg'/>
				<ProjectCard />
				<ProjectCard />
				<ProjectCard />
			</div>
			<div className="card-container">
				<ProjectCard name='Atmospheric' link='https://sora1337.itch.io/atmospheric' imagePath='atmospheric.jpg'/>
				<ProjectCard name='Critical Mass' link='https://anonlineuser.itch.io/critical-mass' imagePath='critical-mass.jpg'/>
				<ProjectCard name='Sqwordle National' link='https://anonlineuser.itch.io/sqwordle-national' imagePath='/sqwordle-national.jpg'/>
			</div>
		</div>
	);
}

export default HomePage;
