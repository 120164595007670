import React, { useState } from 'react';
import "./CatchEmAll.css";

const tempMasteryData = [
	{ championName: "Click the button to load summoner mastery" },
];

const MasteryRow = (props) => {
	const lastPlayedIsSet = props.friendlyLastPlayTime !== undefined;
	const lastPlayedIsAbsolute = lastPlayedIsSet && props.friendlyLastPlayTime.absolute !== undefined;
	return (
		<tr className='MasteryRow'>
			<td className='rowNumber'>{props.rowNumber}</td>
			<td>{props.championName}</td>
			<td>{props.championLevel}</td>
			<td>{props.championPoints}</td>
			<td>
				<span className='last-played-time-amount'>{lastPlayedIsSet ? lastPlayedIsAbsolute ? props.friendlyLastPlayTime.absolute : props.friendlyLastPlayTime.amount : ''}</span>
				<span className='last-played-time-unit'>{lastPlayedIsSet ? lastPlayedIsAbsolute ? '' : props.friendlyLastPlayTime.unit : ''}</span>
			</td>
			<td className={props.challengeRank?.toLowerCase()}>{props.challengeRank}</td>
			<td className={`chest ${props.chestGranted ? 'on' : 'off'}`}><img src='/league-chest.png' /></td>
		</tr>
	)
}

const MasteryRows = (props) => {
	const { data } = props;
	return (
		<tbody>
			{data.map((row, index) =>
				<MasteryRow
					key={index}
					rowNumber={index + 1}
					championName={row.championName}
					championLevel={row.championLevel}
					championPoints={row.championPoints}
					friendlyLastPlayTime={row.friendlyLastPlayTime}
					challengeRank={row.challengeRank}
					chestGranted={row.chestGranted}
				/>
			)}
		</tbody>
	)
}

function CatchEmAll() {

	const [rows, setRows] = useState(tempMasteryData);
	const [sortedRows, setSortedRows] = useState(tempMasteryData);
	const [summonerNameInput, setSummonerName] = useState('');
	const [overrideDataInput, setOverrideData] = useState('');

	const challengeRankThresholds = {
		"Iron": 100,
		"Bronze": 500,
		"Silver": 1000,
		"Gold": 5000,
		"Platinum": 10000,
		"Diamond": 50000,
		"Master": 100000,
		"Grandmaster": 107500,
		"Challenger": 115000,
	};

	function countChampsInRankWithin150(rank) {
		return <tr>
			<td className={rank.toLowerCase()}>{rank}</td>
			<td>{rows.filter((r, index) => index < 150 && r.challengeRank === rank).length}</td>
			<td style={{ color: '#ffffff33' }}>{numberWithCommas(challengeRankThresholds[rank])} points</td>
		</tr>
	}

	return (
		<div className="CatchEmAll">
			<div>
				<div className='input-container'>
					<input id="summonerName" value={summonerNameInput} onInput={e => setSummonerName(e.target.value)} />
					<button onClick={populateTable}>Get Summoner Mastery</button>
					<br />
					<textArea id="overrideData" value={overrideDataInput} placeholder="Optionally, paste JSON from the Riot API..." onInput={e => setOverrideData(e.target.value)} />
				</div>
			</div>
			<table className='champs-in-rank'>
				{countChampsInRankWithin150("Challenger")}
				{countChampsInRankWithin150("Grandmaster")}
				{countChampsInRankWithin150("Master")}
				{countChampsInRankWithin150("Diamond")}
				{countChampsInRankWithin150("Platinum")}
				{countChampsInRankWithin150("Gold")}
				{countChampsInRankWithin150("Silver")}
				{countChampsInRankWithin150("Bronze")}
				{countChampsInRankWithin150("Iron")}
			</table>
			<table className='mastery-table'>
				<thead>
					<tr className='headerRow'>
						<th>#</th>
						<th /* onClick={sortByName} */>Name</th>
						<th /* onClick={sortByLevel} */>Level</th>
						<th /* onClick={sortByPoints} */>Points</th>
						<th /* onClick={sortByPlayTime} */>Last Played</th>
						<th>Challenge Rank</th>
						<th>Chest</th>
					</tr>
				</thead>
				<MasteryRows data={sortedRows} />
			</table>
		</div>
	);


	
	// My Summoner PUUID
	// https://developer.riotgames.com/apis#champion-mastery-v4/GET_getAllChampionMasteriesByPUUID
	// KMK0XM6XXTlR6xVWYZRI6vRwx5v8SOCmWduLbWeNxsasywXR9d1Pf3ebdCNaVfxSQYPLGzd-c1V6Ag
	
	async function populateTable(e) {
		e.preventDefault();

		const willOverrideData = overrideDataInput.length !== 0

		if (summonerNameInput.length === 0 && !willOverrideData) {
			console.log("Enter a summoner name or override data");
			return;
		}

		// fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(`https://americas.api.riotgames.com/riot/account/v1/accounts/by-riot-id/${"an Online User".replace(/ /g, "%20")}/na1?api_key=${apiKey}`)}`)
		// 	.then(response => {
		// 		if (response.ok) return response.json()
		// 		throw new Error('Network response was not ok.')
		// 	})
		// 	.then(data => console.log(data.contents));
		// return;

		setRows([{ championName: "Loading" }]);
		setSortedRows([{ championName: "Loading" }]);

		const championDatas = await getDataDragonRealm("na")
			.then(realm => fetch(`https://ddragon.leagueoflegends.com/cdn/${realm.n.champion}/data/en_US/champion.json`))
			.then(rawResponse => rawResponse.json())
			.then(response => { return response.data; });

		var summonerMastery;
		var summoner;
		if (!willOverrideData) {
			const gameName = summonerNameInput.includes('#') ? summonerNameInput.split('#')[0] : summonerNameInput;
			const tagLine = summonerNameInput.includes('#') ? summonerNameInput.split('#')[1] : 'na1';
			const riotAccount = await getRiotAccount(gameName, tagLine);
			summoner = await getSummonerByPUUID(riotAccount.puuid);
		}

		// Get a modified version of the summoner's mastery list
		summonerMastery = willOverrideData ? JSON.parse(overrideDataInput) : await getMasteryList(summoner.puuid);

		// Add entries for zero-mastery champions
		console.log(summonerMastery);
		const masteryListIds = summonerMastery.map(masteryEntry => `${masteryEntry.championId}`);
		Object.values(championDatas).forEach(championData => {
			if (masteryListIds.indexOf(championData.key) === -1)
				summonerMastery.push({
					"puuid": summoner?.puuid,
					"championId": championData.key,
					"championLevel": 0,
					"championPoints": 0,
					"lastPlayTime": 0,
					"championPointsSinceLastLevel": 0,
					"championPointsUntilNextLevel": 0,
					"chestGranted": false,
					"tokensEarned": 0,
					"summonerId": summoner?.id
				});
		});
		// Populate summonerMastery entries with: championName, friendlyLastPlayTime, and challengeRank
		summonerMastery.forEach(masteryEntry => {
			masteryEntry.championName = Object.values(championDatas).find(championData => championData.key == masteryEntry.championId).name;

			masteryEntry.friendlyLastPlayTime = epochToFriendlyTime(masteryEntry.lastPlayTime);

			let ranksBelowChampPoints = Object.entries(challengeRankThresholds).filter(rank => rank[1] < masteryEntry.championPoints);
			let highestPointRankKVP = ranksBelowChampPoints[ranksBelowChampPoints.length - 1];
			masteryEntry.challengeRank = typeof highestPointRankKVP !== 'undefined' ? highestPointRankKVP[0] : "";

			masteryEntry.championPoints = numberWithCommas(masteryEntry.championPoints);
		});
		setRows(summonerMastery);
		setSortedRows(summonerMastery);
	}

	function sortByName() {
		setSortedRows(rows.sort(function (a, b) {
			if (a.championName < b.championName) {
				return -1;
			}
			if (a.championName > b.championName) {
				return 1;
			}
			return 0;
		}));
	}

	function sortByLevel() {
		setSortedRows(rows.sort(function (a, b) {
			if (a.championLevel > b.championLevel) {
				return -1;
			}
			if (a.championLevel < b.championLevel) {
				return 1;
			}
			return 0;
		}));
	}

	function sortByPoints() {
		setSortedRows(rows.sort(function (a, b) {
			if (a.championPoints > b.championPoints) {
				return -1;
			}
			if (a.championPoints < b.championPoints) {
				return 1;
			}
			return 0;
		}));
	}
	function sortByPoints_Desc() {
		setSortedRows(rows.sort(function (a, b) {
			if (a.championPoints > b.championPoints) {
				return 1;
			}
			if (a.championPoints < b.championPoints) {
				return -1;
			}
			return 0;
		}));
	}

	function sortByPlayTime() {
		setSortedRows(rows.sort(function (a, b) {
			if (a.lastPlayTime > b.lastPlayTime) {
				return -1;
			}
			if (a.lastPlayTime < b.lastPlayTime) {
				return 1;
			}
			return 0;
		}));
	}

	function epochToFriendlyTime(epochTimestamp) {
		if (epochTimestamp === 0) {
			return { 'absolute': "Never" };
		}

		// Convert epoch timestamp to milliseconds
		let currentTime = Date.now();
		let timeDifference = currentTime - epochTimestamp;

		// Define time intervals in milliseconds
		const minute = 60 * 1000;
		const hour = 60 * minute;
		const day = 24 * hour;
		const week = 7 * day;
		const month = 30 * day;
		const year = 365 * day;

		var amount;
		var unit;

		if (timeDifference < minute) {
			return { 'absolute': "Just now" };
		} else if (timeDifference < 2 * hour) {
			const minutes = timeDifference / minute;
			amount = minutes;
			unit = "minute";
		} else if (timeDifference < 2 * day) {
			const hours = timeDifference / hour;
			amount = hours;
			unit = "hour";
		} else if (timeDifference < 2 * week) {
			const days = timeDifference / day;
			amount = days;
			unit = "day";
		} else if (timeDifference < 2 * month) {
			const weeks = timeDifference / week;
			amount = weeks;
			unit = "week";
		} else if (timeDifference < 2 * year) {
			const months = timeDifference / month;
			amount = months;
			unit = "month";
		} else {
			const years = timeDifference / year;
			amount = years;
			unit = "year";
		}

		return {
			'amount': Math.floor(amount),
			'unit': amount === 1 ? unit : unit + 's'
		};
	}

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}


// Catch 'Em All challenge id = 401101

async function getDataDragonRealm(region) {
	return fetch(`https://ddragon.leagueoflegends.com/realms/${region.toLowerCase()}.json`)
		.then(rawResponse => rawResponse.json())
		.then(response => { return response; })
		.catch(error => console.warn(error));
}


// function corsProxyURL(url) { return url; }
// function corsProxyURL(url) { return 'https://corsproxy.io/?' + encodeURIComponent(url); }
async function corsProxyRequest(url) { return await request_allOrigins(url); }

async function request_allOrigins(groupName, url) {
	console.group(groupName);
	return await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`)
		.then(response => {
			if (response.ok) return response.json();
			else throw new Error('Network response was not ok.');
		})
		.then(responseData => {
			return JSON.parse(responseData.contents);
		})
		.then(responseContents => {
			console.log(responseContents);
			return responseContents;
		}).then(responseContents => console.groupEnd());
}

async function request_whateverOrigin(url) {
	return await fetch(`http://www.whateverorigin.org/get?url=${encodeURIComponent(url)}`)
		.then(response => console.log(response));
}

async function request_corsAnywhere(url) {
	return await fetch(`https://cors-anywhere.herokuapp.com/${url}}`)
		.then(response => {
			console.log(response.json());
			if (response.ok) return response.json();
			else throw new Error('Network response was not ok.');
		})
		.then(responseData => {
			console.log(responseData);
		});
}

async function request_costomProxy(url) {
	return await fetch(url, {
		headers: {
			"Access-Control-Allow-Origin": "*"
		}
	})
		.then(response => console.log(response));
}


const apiKey = "RGAPI-e8d8efdd-f821-47f4-a072-58c49e4568a8";
// https://americas.api.riotgames.com/riot/account/v1/accounts/by-riot-id/an%20Online%20User/na1?api_key=RGAPI-e8d8efdd-f821-47f4-a072-58c49e4568a8
// https://cors-anywhere.herokuapp.com/https://americas.api.riotgames.com/riot/account/v1/accounts/by-riot-id/an%20Online%20User/na1?api_key=RGAPI-e8d8efdd-f821-47f4-a072-58c49e4568a8

async function getRiotAccount(gameName, tagLine) {
	console.log(`Getting riot account: ${gameName}#${tagLine}`);
	return await corsProxyRequest(`https://americas.api.riotgames.com/riot/account/v1/accounts/by-riot-id/${gameName.replace(/ /g, "%20")}/${tagLine}?api_key=${apiKey}`)
		// .then(rawResponse => rawResponse.json().contents.json())
		.then(response => {
			console.log(response);
			console.log(response.gameName);
			console.log(`Retrieved riot account: ${response.gameName}#${response.tagLine}`);
			return response;
		})
		.catch(error => console.warn(error));
}

async function getSummonerByPUUID(puuid) {
	console.log(`Getting summoner: ${puuid}`);
	return corsProxyRequest(`https://na1.api.riotgames.com/lol/summoner/v4/summoners/by-puuid/${puuid}?api_key=${apiKey}`)
		.then(rawResponse => rawResponse.json())
		.then(response => {
			console.log(`Retrieved summoner: ${response.name}`);
			return response;
		})
		.catch(error => console.warn(error));
}

async function getMasteryList(puuid) {
	return corsProxyRequest(`https://na1.api.riotgames.com/lol/champion-mastery/v4/champion-masteries/by-puuid/${puuid}?api_key=${apiKey}`)
		.then(rawResponse => rawResponse.json())
		.then(response => { return response; })
		.catch(error => console.warn(error));
}

export default CatchEmAll;
